body {
  margin: 0;
  padding: 0;
  /* background-color:lightcyan; */
  margin-bottom: 50px;
}

.ant-input-search-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  width: 6px !important;
  height: 6px !important;
  background-color: #6f6e7d;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  width: 6px !important;
  height: 6px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 255, 0.25);
  box-shadow: inset 0 0 6px rgba(153, 153, 255, 0.25);
  background-color: rgba(100, 100, 100, 0.1);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.h-100vh {
  height: 100vh;
}
.hover-grey:hover {
  color: #706c61 !important;
}
.hover-red:hover {
  color: #ff0000 !important;
}
.hover-blue:hover {
  color: #1500d2 !important;
}
.mw-150 {
  max-width: 150px;
}
.mw-50 {
  max-width: 50px;
}
.mw-300 {
  max-width: fit-content;
}
.alert-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.alert {
  display: inline-block;
}
.log-list {
  overflow: scroll;
}

/* ========== Scrollbar ========== */

@media only screen and (max-width: 600px) {
  .mw-150 {
    max-width: 80px;
  }
}
.h-fc {
  height: fit-content;
}
/* ================================= Footer ================================= */
.footer-style {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  /* position: absolute;
  bottom: 0; */
}
.github-icon {
  min-width: 30px;
}
.z-10001 {
  z-index: 10001;
}
.object-paragraph {
  word-break: break-all;
}

.log-list {
  height: calc(100vh - 200px);
  min-height: 300px;
  overflow: auto;
}

.ant-table::-webkit-scrollbar-thumb {
  background-color: blue !important;
}

.ant-table::-webkit-scrollbar {
  height: 8px;
}
